<template>
	<div class="line-profile-card">
		<ImageName :name="lineProfile.display_name" :src="lineProfile.profile_image_url"
			size="small">
			<a-icon type="user" />
		</ImageName>
	</div>
</template>

<script>
import ImageName from "@components/common/ImageName.vue"
export default {
	components : {
		ImageName ,
	} ,
	props : {
		lineProfile : {
			type : null,
			default : () => []
		}
	}
}
</script>

<style lang="less" scoped>
.line-profile-card::v-deep {
	padding : 16px 12px;
	margin : 0 auto;
	font-family: @font-family-title;
	max-width : 400px;
	background : #00C300;
	color : @white;
	border-radius : @border-radius-base;
	img {
		border : 2px solid @white;
	}
}
</style>
